import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
const IndexPage = () => (
  <Layout>
    <Head
      title="メンバー紹介"
      description="株式会社アイテクスで働くメンバーをご紹介致します。"
    />
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">メンバー紹介</h1>
          <h2 className="subtitle">Member</h2>
        </div>
      </div>
    </section>
    <section className="section is-medium has-background-white-bis">
      <div className="container">
        <div className="content">
          <p>
            つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          </p>
        </div>
        <div className="columns is-variable is-5 is-multiline">
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>永山 邦哉</p>
            <p>代表取締役 社長</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>幸福 靖</p>
            <p>執行役員/開発部長</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>小川 一彦</p>
            <p>企画開発部長</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>山下 里美</p>
            <p>エンジニア</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>光岡 拓郎</p>
            <p>エンジニア</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>久保 和史</p>
            <p>エンジニア</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>久富木 慎太郎</p>
            <p>ウェブクリエーター</p>
          </div>
          <div className="column is-12-mobile is-6-tablet is-6-desktop is-3-widescreen">
            <figure className="image is-1by1 block">
              <img
                className="has-ratio"
                src="https://placehold.jp/500x500.png"
                alt="ダミー"
              />
            </figure>
            <p>山下 裕恵</p>
            <p>ビジネスサポート</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default IndexPage
